#safetyAppPreviewContainer {
    background-color: #ffffff;
    position: absolute;
    min-height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
    z-index: 1040;
    left: 0;
    top: 0;
}

#safetyAppPreviewContainer table * {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
}

#safetyAppPreviewContainer .page {
    background-color: #ffffff;
}

#safetyAppPreviewContainer table {
    border-spacing: 0;
    border: 2px solid #0e51bd;
    margin: 0 auto 40px auto;
}

#safetyAppPreviewContainer table td,
#safetyAppPreviewContainer table th {
    padding: 0;
    vertical-align: top;
    border: 1px solid #0e51bd;
}

#safetyAppPreviewContainer input,
#safetyAppPreviewContainer textarea,
#safetyAppPreviewContainer select {
    width: 100%;
    padding: 12px 3px 0px 3px;
    border-radius: 0;
    min-height: 30px;
    border: 0 none;
    resize: none;
    background-color: transparent;
    vertical-align: bottom;
    line-height: 1;
}

#safetyAppPreviewContainer textarea {
    min-height: 60px;
}

#safetyAppPreviewContainer th,
#safetyAppPreviewContainer .lightBlueGrey {
    background-color: #eef6ff !important;
    text-transform: uppercase;
}

#safetyAppPreviewContainer img#foremanSignature {
    height: 40px;
    margin: 10px;
}

#safetyAppPreviewContainer label {
    position: absolute;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 9px;
    margin: 2px 0 0 3px;
    color: #0e51bd;
}

#safetyAppPreviewContainer th {
    color: #0e51bd;
    padding: 3px 2px !important;
}

.stripedBG {
    background-image: repeating-linear-gradient(-45deg,
            #eef6ff,
            #eef6ff 2.5px,
            #fff 2.5px,
            #fff 5.0px);
}

.text-large {
    font-size: 16px !important;
    vertical-align: middle !important;
}