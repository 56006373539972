body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

input {
  min-height: 30px;
  min-width: 30px;
}

.btn-link {
  color: #a00306 !important;
  font-weight: bold;
  text-decoration: none !important;
}

.btn-link:hover {
  text-decoration: underline !important;
}

.tablet * {
  font-family: "Arial Narrow", "Helvetica Neue", sans-serif;
  /* font-size: 18px; */
  line-height: 1;
}

div#appNav {
  /* position: absolute; */
  z-index: 2000;
  /* padding-top: 30px; */
  /* margin-left: 20px; */
}

#appNav #dropdownToggle {
  width: 203px;
  height: 65px;
}

@media only screen and (max-width: 600px) {
  code {
    font-size: 1rem;
  }
}

.modal {
  opacity: 1;
}

.pointer {
  cursor: pointer;
}

.toastPanel {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  width: 300px;
  /* background-color: rgba(0, 0, 0, .25); */
  pointer-events: none;
}

.toast {
  pointer-events: visible !important;
  border-radius: 7px !important;
}

.toast-header {
  border-radius: 5px !important;
  border: 0 none !important;
}

.tablet {
  /* width: 1024px !important;
  height: 768px !important;
  max-width: 1024px !important;
  max-height: 768px !important;
  min-width: 1024px !important;
  min-height: 768px !important; */
  /* border: 1px solid #666; */
  padding-top: 30px;
  overflow: hidden;
}

.page {
  padding-top: 30px;
}

.form-label {
  margin: 0 !important;
}

/* .tab-pane {
  max-height: 350px; */
  /* overflow-y: scroll;
} */

.signatureImage {
  position: absolute;
  width: 180px;
  height: 55px;
  /* margin-left: 183px; */
  left: 100px;
  margin-top: 5px;
  z-index: 90;
  border-radius: 0 5px 5px 0;
}

.signatureNames{
  position: relative;
  height: 90px;
  /* margin-left: 183px; */
  margin-left: 50px;
  margin-top: -105px;
  z-index: -90;
  border-radius: 0 5px 5px 0;
}

.signatureNamesPreview{
  height: 90px;
  margin: 5px;
  /* margin-left: 183px; */
  border-radius: 0 5px 5px 0;
}

#logo {
  max-width: 203px;
  max-height: 65px;
}

#userEmail input {
  font-size: 12px;
}

.btn {
  text-transform: uppercase;
  font-weight: bold !important;
}

#tabs {
  display: flex; 
  flex: 1;
  /* overflow-y: auto; */
  background-color: #333;
  z-index: 80;
  height: 60px;
  max-height: 60px;
}

a.nav-link {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  color: #ffffff99;
}

a.active.nav-link {
  color: #fff;
  padding-bottom: 10px;
  background-color: #eef6ff !important;
}

.nav-link:hover {
  border-color: transparent !important;
  color: #888 !important;
}

#remarksTotal,
#signaturesSubmit {
  background-color: #000;
  color: #fff;
}

#remarksTotal .form-control,
#signaturesSubmit .form-control {
  background-color: transparent;
  color: #fff;
  border-color: #a00306 !important;
}

#safetyComments::placeholder,
#repairNeeds::placeholder,
#ForemanSignature::placeholder,
#EmployeeSignature::placeholder,
#EmployeeSignatureInput::placeholder,
#RepairNeeds::placeholder,
#remarksTotal .form-control::placeholder,
#signaturesSubmit .form-control::placeholder,
.form-control::placeholder {
  color: #999 !important;

}

#remarksTotal #Total {
  text-align: right;
  width: 120px;
  border: 0 none;
  background-color: #fff;
  border-radius: 0;
  color: #000;
  font-weight: bold;
  font-size: 22px;
}

.nav-tabs {
  border-bottom: 0 none !important;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  opacity: .25 !important;
}

.dropdown-item {
  padding: 10px 0;
  margin: 10px 0;
}

.dropdown-item a {
  color: #000;
  text-decoration: none;
  font-weight: bold;
}

.dropdown-menu {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, .5);
}

#entriesCount {
  width: 20px;
  height: 20px;
  background-color: #666;
  color: #fff;
  align-items: center;
  font-size: 12px;
  justify-content: center;
  display: flex;
  border-radius: 50%;
}

textarea {
  resize: none !important;
}

.faButton {
  min-width: 46px;
  height: 40px;
}

.finePrint {
  font-size: 13px !important;
}

#ForemanSignature,
#EmployeeSignature {
  width: 304px;
}

.pointer {
  cursor: pointer;
}

#logo,
#dropdownToggle {
  cursor: pointer;
}

.dropdown-menu a {
  display: block;
}

.entries td {
  vertical-align: middle;
}

.navSmall {
  font-size: 12px;
}

table {
  border-radius: 3px;
}

.json-editor-btn-collapse {
  margin: 5px 20px;
  min-width: 30px;
  min-height: 30px;
  background-color: #959595;
  color: #000;
  font-weight: bold;
  border: 0 none;
  border-radius: 3px;
  font-size: 14px;
}

/* .json-editor-btn-collapse span {
  display: none;
}
.json-editor-btn-collapse::after {
  content: '\FF0B';
} */

.json-editor-btn-add {
  margin: 5px 20px;
  min-width: 30px;
  min-height: 30px;
  background-color: #39b54a;
  color: #fff;
  border: 0 none;
  border-radius: 3px;
}

.json-editor-btn-add span {
  display: none;
}

.json-editor-btn-add::after {
  content: '\FF0B';
}

.json-editor-btn-delete {
  margin: 5px 20px;
  min-width: 30px;
  min-height: 30px;
  background-color: #dc3545;
  color: #fff;
  border: 0 none;
  border-radius: 3px;
}

.json-editor-btn-delete span {
  display: none;
}

.json-editor-btn-delete::after {
  content: '\2715';
}

.json-editor-btn-movedown,
.json-editor-btn-moveup {
  margin: 5px;
  min-width: 30px;
  min-height: 30px;
  background-color: #a00306;
  color: #fff;
  border: 0 none;
  border-radius: 3px;
}

.json-editor-btn-movedown span,
.json-editor-btn-moveup span {
  display: none;
}

.json-editor-btn-movedown::after {
  content: '\25BC'
}

.json-editor-btn-moveup::after {
  content: '\25B2'
}

#config input {
  border: 0 none;
}

.smallText {
  font-size: 10px;
}

div#safetyChecklists {
  color: #fff;
}

#appHeader {
  height: 65px; 
  background-color: #000000;
}

#appContent {
  padding-top: 65px;
}

#safetyAppContainer {
  display: flex; 
  flex-direction: column;
  height: 100vh;
}

#dailyReportHeader {
  height: 65px;
  position: sticky !important;
}

#safetyAppHeader {
  /* height: 200px; */
  background-color: #000;
}

#safetyAppContent {
  flex: 1;
  background-color: #fff;
  z-index: 90;
  /* height: 100px; */
  max-height: 104px;
  /* overflow-y: auto; */
}

#dailyReportFooter {
  height: 64px;
}

.lightBlueGrey {
  background-color: #eef6ff !important;
  /* color: #000 !important; */
}

#tabContent .custom-radio {
  cursor: pointer;
  font-size: 36px; /* Icon size */
  font-weight: bold;
  color: #ddd; /* Default icon color */
}

#tabContent input[type="radio"]:checked + label .fa {
  color: #007BFF; /* Change icon color when selected */
}

#tabContent input[type="radio"] {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute; /* Ensures the input does not affect layout */
}

#tabContent .radio-container {
  position: relative;
  display: inline-block; /* or 'block' based on your design */
}

#tabContent .checkedYes:checked + label.yes {
  color: green; /* Green when 'Yes' is selected */
}

#tabContent .checkedNo:checked + label.no {
  color: red; /* Red when 'No' is selected */
}

#tabContent .checkedNotApplicable:checked + label.notApplicable {
  color: blue; /* Red when 'Not applicable' is selected */
}

#tabContent {
  display: flex;
  flex-grow: 1; /* Takes up all available space */
    overflow-y: auto; /* Adds scrollability if content overflows */
}